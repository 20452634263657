var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"newsList_head moveTopNormal"},[_c('div',{staticClass:"newsList_head_boder"},[_c('div',{staticClass:"newsList_head_title",class:_vm.active == 0 ? ' active' : '',on:{"click":function($event){_vm.active = 0;
          _vm.getList();}}},[_vm._v("\n        新闻资讯\n      ")]),_c('div',{staticClass:"newsList_head_title",class:_vm.active == 1 ? ' active' : '',on:{"click":function($event){_vm.active = 1;
          _vm.getList();}}},[_vm._v("\n        媒体资讯\n      ")]),_c('div',{staticClass:"newsList_head_title",class:_vm.active == 2 ? ' active' : '',on:{"click":function($event){_vm.active = 2;
          _vm.getList();}}},[_vm._v("\n        行业资讯\n      ")]),_c('div',{staticClass:"newsList_head_title",class:_vm.active == 3 ? ' active' : '',on:{"click":function($event){_vm.active = 3;
          _vm.getList();}}},[_vm._v("\n        热点专题\n      ")])])]),_c('div',{staticClass:"newsList_list"},[_c('div',{staticClass:"newsList_list_table"},[_c('ul',{staticClass:"newsList_list_table_ul"},_vm._l((_vm.newsList),function(item){return _c('li',{key:item.id,staticClass:"newsList_list_table_ul_li moveTopNormal",on:{"click":function($event){return _vm.toDetails(item)}}},[_c('div',{staticClass:"newsList_list_table_ul_left",staticStyle:{"width":"450px","height":"230px","overflow":"hidden","margin-right":"40px","position":"relative"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.imgSuffix),expression:"imgSuffix"}],staticClass:"img_loading",attrs:{"src":require("../../static/newImg/img_loading.png")}}),(item.newsCover)?_c('img',{staticStyle:{"width":"450px"},attrs:{"src":item.newsCover + _vm.imgSuffix}}):_c('img',{attrs:{"src":require("../../static/image/home_news.png"),"height":"180px","width":"450px"}})]),_c('div',{staticClass:"moveRight newsList_list_table_ul_right"},[_c('div',{staticClass:"newsList_list_table_ul_li_time"},[_vm._v("\n              发布时间："+_vm._s(item.year)+"-"+_vm._s(item.day)+"\n            ")]),_c('div',{staticClass:"newsList_list_table_ul_li_title"},[_vm._v("\n              "+_vm._s(item.newsTitle.length > 25
                  ? item.newsTitle.substring(0, 25) + "..."
                  : item.newsTitle)+"\n            ")]),_c('div',{staticClass:"newsList_list_table_ul_li_content"},[_vm._v("\n              "+_vm._s(item.newsIntroduction
                  ? item.newsIntroduction.length > 30
                    ? item.newsIntroduction.substring(0, 30) + "..."
                    : item.newsIntroduction
                  : item.title)+"\n            ")])])])}),0)]),_c('div',{staticClass:"newsList_list_right"},[_c('div',{staticClass:"newsList_list_right_title moveTopNormal"},[_vm._v("推荐新闻")]),_vm._l((_vm.recommendList),function(item){return _c('div',{key:item.id,staticClass:"newsList_list_right_item moveTopNormal",on:{"click":function($event){return _vm.toDetails(item)}}},[_c('div',{staticClass:"newsList_list_right_item_date"},[_vm._v("\n          发布时间： "+_vm._s(item.year)+"."+_vm._s(item.day)+"\n        ")]),_c('div',{staticClass:"newsList_list_right_item_newsTitle"},[_vm._v("\n          "+_vm._s(item.newsTitle)+"\n        ")])])})],2)]),_c('div',{staticClass:"newsList_page"},[_c('div',{staticClass:"newsList_pagination"},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","page-size":6,"total":_vm.query.total},on:{"current-change":_vm.handleCurrentChange}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newsList_bg scaleBanner"},[_c('div',{staticClass:"newsList_bg_name moveTopTitle"},[_vm._v("新闻中心")])])}]

export { render, staticRenderFns }